

.ant-modal-body{
    padding: 12px!important;
}





@media (max-width:350px)  {
    .btn-want{
        visibility: hidden;
        font-size:0px;
        line-height:0px;
        font-family:PingFang SC;
        font-weight:0;
        color:#666666;
        padding-top:0px;
        padding-bottom:0px;
    }
}
@media (min-width:375px)  { 
    .btn-want{
        font-size:14px;
        line-height:20px;
        font-family:PingFang SC;
        font-weight:400;
        color:#666666;
        padding-top:5px;
        padding-bottom:5px;
    }
}

.dial-input:hover{
    border-color: lightgray!important;
}

.dial-style-remove-ant:active,.dial-style-remove-ant:hover{
    border-color:rgb(217, 217, 217)!important;
    color:rgb(217, 217, 217)!important;
}