.content {
    /* min-height: 280px; */
    /* padding: 24px; */
    background:#F2F2F2;
    flex:1 1 auto;
  }


  .footer{

    background: #F7F7F7;
    flex:0 1 auto;
    min-height: 99px;
    position: sticky;
    top: -1px;
  }

  
.container {
    width:100%;
    height: 100%;
    /* background:grey; */
    display:flex;
    flex-direction:column;
  }

  /* html,body {
    height: 100%;
    margin: 0;
    overflow: hidden;
 } */

html, body {
    height: 100%;
}


@media (min-width:769px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .container{
    width: 769px;
    margin-left: calc( 50% - 385px);
  }
}



.input-title{
  animation:
    nudge 2s linear infinite alternate;
}


@keyframes nudge {
  0%, 100% {
    /* transform: translate(0, 0); */
    width: 0px;
  }
  
  50% {
    /* transform: translate(10px, 0); */
    width: 15px;
  }
  
  /* 80% {
    transform: translate(-10px, 0);
  } */
}