
.lawbook-type-container > .ant-radio-button-wrapper{
    /* margin: 6px!important; */
    margin-top: 4px!important; 
    margin-bottom: 4px!important;
    margin-left: 6px!important;
    margin-right: 6px!important;
    min-width: calc(33% - 10px);
    padding-left: 4px!important;
    padding-right: 4px!important;
    text-align: center!important;
    /* border-radius: 4px!important; */
}
@media (max-width:350px)  {
    .container-margin{
        margin-left: 6px!important;
        margin-right: 6px!important;
    }
    .lawbook-type-container > .ant-radio-button-wrapper{
        margin-top: 4px!important; 
        margin-bottom: 4px!important;
        margin-left: 2px!important;
        margin-right: 2px!important;
        min-width: 98px;
        padding-left: 2px!important;
        padding-right: 2px!important;
        text-align: center!important;
        /* border-radius: 4px!important; */
    }
}
@media (min-width:375px)  { 
    .container-margin{
        margin-left: 15px!important;
        margin-right: 15px!important;
    }
    .lawbook-type-container > .ant-radio-button-wrapper{
        /* margin: 6px!important; */
        margin-top: 5px!important; 
        margin-bottom: 5px!important;
        margin-left: 4px!important;
        margin-right: 4px!important;
        min-width: calc(33% - 10px);
        padding-left: 4px!important;
        padding-right: 4px!important;
        text-align: center!important;
        /* border-radius: 4px!important; */
    }
}

.container-margin  .ant-radio-button{
    background-color: #FF8600!important;
    border-bottom-color:#FF8600!important;
}

.container-margin .ant-radio-button-wrapper-checked{
    /* background-color: white!important;
    border-bottom-color:white!important; */
    border-color: #FF8600!important;
}


.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #FF8600!important;
}

.ant-radio-button-wrapper:hover{
    color: black!important;
}

.dial-input:hover{
    border-color: lightgray!important;
}
