
/* .lawsuit-type-container > .ant-radio-button-wrapper{ */
    /* margin: 6px!important; */
    /* margin-top: 4px!important;  */
    /* margin-bottom: 4px!important; */
    /* margin-left: 6px!important; */
    /* margin-right: 6px!important; */
    /* min-width: 95px; */
    /* border-radius: 4px!important; */
/* } */


@media (max-width:350px)  {
    .block-top{
        margin-top: 10px;
    }
    .block-bottom{
        margin-bottom: 10px;
    }
    .container-margin{
        margin-left: 6px!important;
        margin-right: 6px!important;
    }
    .lawsuit-type-container > .ant-radio-button-wrapper{
        margin-top: 4px!important; 
        margin-bottom: 4px!important;
        margin-left: 2px!important;
        margin-right: 2px!important;
        min-width: 95px;
        padding-left: 2px!important;
        padding-right: 2px!important;
        text-align: center!important;
        /* border-radius: 4px!important; */
    }
}
@media (min-width:375px)  { 
    .block-top{
        margin-top: 18px;
    }
    .block-bottom{
        margin-bottom: 18px;
    }
    .container-margin{
        margin-left: 15px!important;
        margin-right: 15px!important;
    }
    .lawsuit-type-container > .ant-radio-button-wrapper{
        /* margin: 6px!important; */
        margin-top: 5px!important; 
        margin-bottom: 5px!important;
        margin-left: 4px!important;
        margin-right: 4px!important;
        min-width: calc(33% - 10px);
        padding-left: 4px!important;
        padding-right: 4px!important;
        text-align: center!important;
        /* border-radius: 4px!important; */
    }
}
  